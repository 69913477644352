'use client';

import React from 'react';
import { useIntercom } from 'react-use-intercom';

import { Button } from '@zealy/design-system';

export const OpenSupportButton = ({ title = 'Contact support' }) => {
  const intercom = useIntercom();

  return (
    <Button onClick={intercom.show} variant="muted">
      {title}
    </Button>
  );
};
